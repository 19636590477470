import React from 'react'
import PropTypes from 'prop-types'

import Image from './Image'
import Content from './Content'
import './PageHeader.css'

const PageHeader = ({
  title,
  subtitle,
  backgroundImage,
  showImage = false,
  large,
  className = ''
}) => {
  if (large) className += ' PageHeader-large'
  let mainClass = `${showImage && backgroundImage ? 'PageHeader--Image' : 'PageHeader'}`
  let [titlePrefix, ...titleRest] = title.split(' ')
  return (
    <div className={`${mainClass} relative ${className}`}>
      {showImage && backgroundImage && (
        <Image
          background
          resolutions="large"
          src={backgroundImage}
          alt={title}
          size="cover"
        />
      )}
      <div className="container relative">
        {title && (
            <h1 className="PageHeader--TitleWrap">
              <span className="PageHeader--TitlePre">{titlePrefix} </span>
              <span className="PageHeader--Title">{titleRest}</span>
            </h1>
        )}
        {subtitle && (
          <Content className="PageHeader--Subtitle" src={subtitle} />
        )}
      </div>
    </div>
  )
}

PageHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string
}

export default PageHeader
